import AbstractComponent from "../../../strapi_types/AbstractComponent";
import Image from "next/image";
import backgroundImage from "../../../public/img/backgroundPrice.jpg";
import PriceCatalog, { PriceListCatalogType } from "./PriceListCatalog";
import Title from "../../Title";
import Subtitle from "../../Subtitle";

export interface PriceListProps extends AbstractComponent {
  title: string;
  subtitle: string;
  priceCatalog: PriceListCatalogType[];
}

const PriceList: React.FunctionComponent<PriceListProps> = (props) => {
  return (
    <section
      className="relative flex flex-col items-start justify-center w-full px-4 pb-12 text-white pt-11"
      id="price-list"
    >
      <Image
        src={backgroundImage}
        layout="fill"
        alt="Background"
        className="z-[-5] bg-opacity-50 object-cover object-center "
      />

      <div className="w-full mx-auto max-w-container">
        <Title
          style={
            "mb-1 text-3xl text-center text-white desktop:hidden uppercase"
          }
        >
          {props.title}
        </Title>
        <Subtitle
          style={
            "text-lg font-light leading-5 text-center font-roboto desktop:hidden"
          }
        >
          {props.subtitle}
        </Subtitle>

        <div className="flex flex-col desktop:space-x-16 desktop:flex-row">
          {props.priceCatalog.map(
            (catalog: PriceListCatalogType, index: number) => {
              return <PriceCatalog key={index} catalog={catalog} />;
            }
          )}
        </div>
      </div>
    </section>
  );
};

export default PriceList;
