import classNames from "classnames";

interface ButtonProps {
  buttonTitle: string;
  buttonStyle?: string;
  onButtonClick: () => void;
  enabled?: boolean;
}

const Button: React.FunctionComponent<ButtonProps> = (props) => {
  const buttonClass = classNames(
    "items-center justify-center text-sm font-medium text-white cursor-pointer bg-accent z-50 mt-6 desktop:mt-8 ",
    props.buttonStyle,
    { "bg-darkgrey cursor-not-allowed": props.enabled == false }
  );

  return (
    <button
      className={
        "w-[184px] h-10 desktop:w-64 desktop:h-12 desktop:text-lg uppercase leading-lnh20 desktop:leading-lnh26 font-oswald text-sm " +
        buttonClass
      }
      onClick={() => {
        props.onButtonClick();
      }}
      disabled={props.enabled == false}
    >
      {props.buttonTitle}
    </button>
  );
};

export default Button;
