import { useEffect, useState } from "react";

const useStickyFloatMenu = (): boolean => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const screenHeight = window.innerHeight;
    const screenWidth = window.innerWidth;

    const listenToScroll = () => {
      const heightToHideFrom =
        screenWidth < 1024 ? screenHeight / 2 + 56 : screenHeight / 2 + 62;
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;

      if (winScroll > heightToHideFrom) {
        isVisible && setIsVisible(false);
      } else {
        !isVisible && setIsVisible(true);
      }
    };
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, [isVisible]);
  return isVisible;
};

export default useStickyFloatMenu;
