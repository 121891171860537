/* eslint-disable @next/next/no-img-element */
import classNames from "classnames";
import { HiPlusCircle } from "react-icons/hi";

interface GalleryImageProps {
  url: string;
  index: number;
  mediumUrl: string;
  openGallery: (index: number) => void;
}

const GalleryImage: React.FunctionComponent<GalleryImageProps> = (props) => {
  const isBlured = props.index < 3;

  const imgClass = classNames({
    "object-cover w-full h-full": true,
    "blur-sm": !isBlured,
  });

  const IconHiPlusCircle = () =>
    props.index < 3 ? null : (
      <div className="absolute top-0 left-0 w-full h-full">
        <HiPlusCircle className="w-full h-full text-white p-7" />
      </div>
    );
  return (
    <div
      className="phone:h-[45.5vw] w-[94vw] h-[94vw] phone:w-auto relative desktop:h-desktopGallerySize desktop:w-desktopGallerySize  cursor-pointer overflow-hidden"
      onClick={() => props.openGallery(props.index)}
    >
      <picture>
        <source
          media="(min-width:650px)"
          srcSet={props.url}
          className={imgClass}
        />
        <img
          src={props.mediumUrl}
          alt="Gallery Image"
          className={imgClass}
          loading="lazy"
        />
      </picture>
      <IconHiPlusCircle />
    </div>
  );
};

export type { GalleryImageProps };
export default GalleryImage;
