/* eslint-disable @next/next/no-img-element */
import { RiCloseFill } from "react-icons/ri";
import { GalleryImage } from "../Sections/Gallery";
import PreviousImageButton from "../PreviousImageButton";
import NextImageButton from "../NextImageButton";

interface GalleryPreviewProps {
  openedGallery: boolean;
  activeImageId: number;
  toggleOpenedGallery: (value: boolean) => void;
  changeGalleryImage: (id: number) => void;
  gallery: GalleryImage[];
}

const GalleryPreview: React.FunctionComponent<GalleryPreviewProps> = (
  props
) => {
  const baseUrl = process.env.NEXT_PUBLIC_STRAPI_URL;

  const Gallery = props.openedGallery ? (
    <>
      <div
        className="fixed top-0 left-0 flex items-center justify-center w-fullvw h-fullvh bg-backdrop z-zMax"
        onClick={() => {
          props.toggleOpenedGallery(props.openedGallery);
        }}
      />
      <RiCloseFill
        className="fixed w-20 h-20 p-4 text-white cursor-pointer right-1 top-1 z-[300]"
        onClick={() => {
          props.toggleOpenedGallery(props.openedGallery);
        }}
      />
      <div className="fixed top-0 flex flex-col items-center justify-center m-auto cursor-pointer h-fullvh z-zMax">
        <div className="flex justify-center text-xl text-white ">
          {props.activeImageId + 1}/{props.gallery.length}
        </div>

        <div>
          <PreviousImageButton
            changeGalleryImage={(id) => {
              props.changeGalleryImage(id);
            }}
            activeImageId={props.activeImageId}
          />
          <NextImageButton
            changeGalleryImage={(id) => {
              props.changeGalleryImage(id);
            }}
            activeImageId={props.activeImageId}
          />
          <img
            src={baseUrl + props.gallery[props.activeImageId].url}
            alt="Gallery Image"
            className="max-h-[80vh] relative"
          />
        </div>
      </div>
    </>
  ) : null;
  return <>{Gallery}</>;
};

export default GalleryPreview;
