interface CatalogItemType {
  serviceName: string;
  servicePrice: number;
}

interface PriceListCatalogItemProps {
  item: CatalogItemType;
}

const PriceListCatalogItem: React.FunctionComponent<
  PriceListCatalogItemProps
> = (props) => {
  return (
    <div className="flex mt-4 text-lg font-light font-roboto leading-lnh21 desktop:leading-lnh28 desktop:text-2xl">
      <p>{props.item.serviceName}</p>
      <div className="flex-grow mb-2 border-b-2 border-white border-dotted" />
      <p className="text-accent">{props.item.servicePrice}KM</p>
    </div>
  );
};

export type { CatalogItemType };
export default PriceListCatalogItem;
