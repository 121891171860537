import AbstractComponent from "../../strapi_types/AbstractComponent";
import Title from "../Title";
import manWithBeard from "../../public/img/manwithbeard.png";
import LinkButton from "../LinkButton";

export interface HairTransplantProps extends AbstractComponent {
  title?: string;
  content?: string;
  src?: HTMLImageElement;
}

const HairTransplant: React.FunctionComponent<HairTransplantProps> = () => {
  return (
    <section
      className="py-4  mx-auto my-14 max-w-container lg:flex"
      id="hairtransplant"
    >
      <div className="w-1/2 pl-16 lg:block hidden">
        <img src={manWithBeard.src} alt="Muskarac sa kosom i bradom" />
      </div>
      <div className="text-lg font-light text-left font-roboto desktop:text-2xl leading-lnh21 desktop:leading-lnh28 lg:w-1/2 pr-16 flex items-center lg:mx-0 mx-4">
        <div>
          <Title style={"text-flatblack text-5xl mb-4"}>
            Program presađivanja kose
          </Title>
          <p className="lg:pt-0 pt-4">
            Ukoliko imate problem sa opadanjem kose znajte da postoji opcija za
            Vas.
          </p>
          <br />
          <p>Od danas nudimo tretman za liječenje muškog tipa ćelavosti.</p>
          <br />
          <p className="pb-4">
            Za sva Vaša pitanja u vezi cijene, datuma i same procedure posjetite
            stranicu ispod:
          </p>
          <LinkButton
            className="!h-16"
            onButtonClick={() => {
              return;
            }}
            href="/presadjivanje-kose"
          >
            Saznaj Više
          </LinkButton>
        </div>
      </div>
      <div className="block pt-12 lg:hidden mx-4">
        <img
          className="m-auto"
          src={manWithBeard.src}
          alt="Muskarac sa kosom i bradom"
        />
      </div>
    </section>
  );
};

export default HairTransplant;
