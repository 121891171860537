import AbstractComponent from "../../../strapi_types/AbstractComponent";
import Button from "../../Button";
import { useState } from "react";
import Overlay from "./Overlay";
import useUserScrolled from "../../../hooks/useUserScrolled";

export interface MapProps extends AbstractComponent {
  iframe: string;
}

const Map: React.FunctionComponent<MapProps> = (props) => {
  const [overlayVisible, setOverlayVisible] = useState(true);

  const shouldMapLoad = useUserScrolled();

  return (
    <div className="w-full h-[40vh] z-50 bg-backdrop my-9 relative desktop:h-fullvh desktop:mb-24">
      {shouldMapLoad ? (
        <iframe
          src={props.iframe}
          className="w-full h-full"
          allowFullScreen={true}
          loading="lazy"
          title="Location"
        ></iframe>
      ) : null}
      <Overlay
        overlayVisible={overlayVisible}
        onOverlayClick={() => {
          setOverlayVisible(false);
        }}
      >
        <Button
          buttonTitle="MAPA"
          buttonStyle=""
          onButtonClick={() => {
            setOverlayVisible(false);
          }}
        />
      </Overlay>
    </div>
  );
};

export default Map;
