import Link from "next/link";
import classNames from "classnames";

interface ButtonProps {
  children: React.ReactNode;
  onButtonClick: () => void;
  className?: string;
  href: string;
  enabled?: boolean;
}

const LinkButton: React.FunctionComponent<ButtonProps> = (props) => {
  const buttonClass = classNames(
    "items-center justify-center text-sm font-medium text-white cursor-pointer bg-accent z-50 mt-6 desktop:mt-8 w-[184px] h-10 desktop:w-64 desktop:h-12 desktop:text-lg uppercase leading-lnh20 desktop:leading-lnh26 font-oswald text-sm text-center flex items-center justify-center ",
    props.className,
    { "bg-darkgrey cursor-not-allowed": props.enabled == false }
  );

  return (
    <Link href={props.href}>
      <a
        className={buttonClass}
        onClick={() => {
          props.onButtonClick();
        }}
      >
        {props.children}
      </a>
    </Link>
  );
};

export default LinkButton;
