import { useState } from "react";

const useOneTimeClick = (): [(callbackfn: () => void) => void, boolean] => {
  const [clicked, setClicked] = useState(false);

  const dispatch = (callbackfn: () => void) => {
    if (clicked == false) {
      setClicked(true);
      callbackfn();
    }
  };
  return [dispatch, clicked];
};

export default useOneTimeClick;
