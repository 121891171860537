import React from "react";
import AbstractComponent from "../../strapi_types/AbstractComponent";
import Description, { DescriptionProps } from "./Description";
import HairTransplant from "./HairTransplant";
import Hero, { HeroProps } from "./Hero";
import PriceList, { PriceListProps } from "./PriceList";
import Gallery, { GalleryProps } from "./Gallery";
import Map, { MapProps } from "./Map/Map";

interface SectionRouterProps {
  section: AbstractComponent;
}

const SectionRouter: React.FunctionComponent<SectionRouterProps> = ({
  section,
}: SectionRouterProps) => {
  switch (section.__component) {
    case "page-components.hero-section":
      return <Hero {...(section as HeroProps)} />;
    case "page-components.description-section":
      return (
        <>
          <Description {...(section as DescriptionProps)} />
          <HairTransplant __component="" />
        </>
      );

    case "page-components.price-section":
      return <PriceList {...(section as PriceListProps)} />;
    case "page-components.gallery-section":
      return <Gallery {...(section as GalleryProps)} />;
    case "page-components.map-section":
      return <Map {...(section as MapProps)} />;
    default:
      return <></>;
  }
};

export default SectionRouter;
