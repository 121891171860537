import PriceItem, { CatalogItemType } from "./PriceListCatalogItem";

interface PriceListCatalogType {
  catalogName: string;
  index: number;
  serviceItems: CatalogItemType[];
}
interface PriceListCatalogProps {
  catalog: PriceListCatalogType;
}

const PriceListCatalog: React.FunctionComponent<PriceListCatalogProps> = (
  props
) => {
  return (
    <div className="z-50 justify-between mt-11 desktop:flex-grow">
      <h3 className="mb-4 text-3xl font-semibold uppercase desktop:mb-8 desktop:text-5xl leading-lnh44 desktop:leading-lnh71">
        {props.catalog.catalogName}
      </h3>
      {props.catalog.serviceItems.map((item, index: number) => {
        return <PriceItem item={item} key={index} />;
      })}
    </div>
  );
};
export type { PriceListCatalogType };
export default PriceListCatalog;
