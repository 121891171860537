interface SubtitleProps {
  children: React.ReactNode;
  style?: string;
}

const Title: React.FunctionComponent<SubtitleProps> = (props) => {
  return (
    <p
      className={
        "font-light text-left font-roboto desktop:text-2xl text-lg leading-lnh21 desktop:leading-lnh28 " +
        props.style
      }
    >
      {props.children}
    </p>
  );
};

export default Title;
