import { useEffect, useState } from "react";

const useUserScrolled = (): boolean => {
  const [userScrolled, setUserScrolled] = useState(false);

  useEffect(() => {
    const listenToScroll = () => {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (winScroll > 300 && userScrolled != true) {
        setUserScrolled(true);
      }
    };
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, [userScrolled]);
  return userScrolled;
};
export default useUserScrolled;
