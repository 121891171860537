import { useEffect } from "react";
import type { NextPage } from "next";
import { GetStaticProps } from "next";
import Head from "next/head";
import PageCollection from "../strapi_types/PageCollection";
import fetchStrapiPage from "../lib/fetchStrapiPage";
import SectionContainer from "../components/Sections/SectionContainer";
import { useDispatch } from "react-redux";
import { fetchAppointments } from "../appointment-redux/customer/extra-reducers/fetchAppointments";
import useCustomerSession from "../appointment-redux/customer/useCustomerSession";
import useStickyFloatMenu from "../hooks/useStickyFloatMenu";
import useOneTimeClick from "../hooks/useOneTimeClick";
import * as gtag from "../lib/gtag";
import Link from "next/link";

interface HomeProps {
  pageContent: PageCollection;
}

const Home: NextPage<HomeProps> = (props) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSessionStarted, userToken, userHasAppointment] =
    useCustomerSession();

  const isStickyFloatMenuVisible = useStickyFloatMenu();

  useEffect(() => {
    userToken ? dispatch(fetchAppointments(userToken)) : null;
  }, [dispatch, userToken]);

  const [oneTimeClick, clicked] = useOneTimeClick();

  return (
    <div className="w-full font-oswald">
      <Head>
        <title>{props.pageContent.pageTitle}</title>
        <meta
          name="description"
          content="Potrebna ti je nova frizura, ali nemaš vremena da čekaš? Zakaži odmah!"
        />
        <meta
          name="keywords"
          content="frizerski salon, frizura, brijanje, brada, zakazivanje, zakazati, prnjavor, barber, barbershop, tatarevic, termin"
        />
      </Head>

      {props.pageContent.sections[0].buttonTitle &&
      !isStickyFloatMenuVisible ? (
        <Link href="/appointment" passHref>
          <a
            className="fixed top-0 right-0 h-12 mt-0 z-zMax desktop:mt-0 w-[184px] desktop:w-64 desktop:h-12 desktop:text-lg items-center justify-center text-sm font-medium text-white cursor-pointer bg-accent flex uppercase leading-lnh20 desktop:leading-lnh26 font-oswald  text-center"
            onClick={() => {
              oneTimeClick(() => {
                gtag.event({
                  action: "sticky_click",
                  category: "flow",
                  label: "sticky_click",
                  value: 0,
                });
              });
            }}
          >
            {!clicked
              ? props.pageContent.sections[0].buttonTitle
              : "Učitava se..."}
          </a>
        </Link>
      ) : null}
      <SectionContainer pageContent={props.pageContent} />
    </div>
  );
};

export const getStaticProps: GetStaticProps<HomeProps> = async () => {
  const data = await fetchStrapiPage("home");
  return {
    props: {
      pageContent: data,
    },
  };
};

export const APP_VERSION = "v1.0.0";

export default Home;
