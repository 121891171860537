import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../store";
import {
  userHasAppointmentSelector,
  userTokenSelector,
} from "./appointmentSelectors";
import { HasAppointmentEnum } from "./appointmentTypes";
import { authenticateUser } from "./extra-reducers/authenticateUser";
import { checkUserAppointment } from "./extra-reducers/checkUserAppointment";

const useCustomerSession: () => [boolean, string | null, string] = () => {
  const dispatch = useDispatch();
  const userToken = useAppSelector(userTokenSelector);
  const userHasAppointment = useAppSelector(userHasAppointmentSelector);
  const isSessionStarted = !!userToken;

  useEffect(() => {
    if (!userToken) {
      dispatch(authenticateUser(""));
    }
  }, [dispatch, userToken]);

  useEffect(() => {
    if (userToken && userHasAppointment === HasAppointmentEnum.NotChecked) {
      dispatch(checkUserAppointment(userToken));
    }
  }, [userToken, userHasAppointment, dispatch]);

  return [isSessionStarted, userToken, userHasAppointment];
};

export default useCustomerSession;
