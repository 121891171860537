import Image from "next/image";
import backgroundImage from "../../public/img/background.jpg";
import AbstractComponent from "../../strapi_types/AbstractComponent";
import Subtitle from "../Subtitle";
import useCustomerSession from "../../appointment-redux/customer/useCustomerSession";
import { HasAppointmentEnum } from "../../appointment-redux/customer/appointmentTypes";
import * as gtag from "../../lib/gtag";
import useOneTimeClick from "../../hooks/useOneTimeClick";
import LinkButton from "../LinkButton";

export interface HeroProps extends AbstractComponent {
  title: string;
  subtitle: string;
  buttonTitle: string;
}

const Hero: React.FunctionComponent<HeroProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSessionStarted, userToken, userHasAppointment] =
    useCustomerSession();

  const buttonHref =
    userHasAppointment === HasAppointmentEnum.HasAppointment
      ? "/user-appointment"
      : "/appointment";

  const [oneTimeClick, clicked] = useOneTimeClick();

  return (
    <section className="relative text-white w-full h-[calc(100vh-48px)] flex items-center justify-center flex-col text-center">
      <Image
        src={backgroundImage}
        layout="fill"
        alt="Background"
        className="z-[-5] bg-opacity-50 object-cover object-left-top"
      />
      <h1 className="mx-4 text-3xl font-semibold uppercase font-oswald desktop:text-5xl leading-lnh44 desktop:leading-lnh71">
        {props.title}
      </h1>
      <Subtitle style="text-center mt-1 desktop:mt-2 max-w-[388px] desktop:max-w-[588px] mx-4">
        {userHasAppointment === HasAppointmentEnum.HasAppointment
          ? "Uspješno ste zakazali termin. Možete ga pregledati klikom na dugme ispod."
          : props.subtitle}
      </Subtitle>
      <LinkButton
        onButtonClick={() => {
          oneTimeClick(() => {
            gtag.event({
              action: "hero_click",
              category: "flow",
              label: "hero_click",
              value: 0,
            });
          });
        }}
        href={buttonHref}
      >
        {!clicked
          ? userHasAppointment === HasAppointmentEnum.HasAppointment
            ? "Pregledaj termin"
            : props.buttonTitle
          : "Učitava se..."}
      </LinkButton>
    </section>
  );
};

export default Hero;
