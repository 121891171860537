interface TitleProps {
  style?: string;
  children: React.ReactNode;
}

const Title: React.FunctionComponent<TitleProps> = (props) => {
  return (
    <h2
      className={
        "text-3xl font-semibold font-oswald mb-4 desktop:mb-8 desktop:text-5xl leading-lnh44 desktop:leading-lnh71 " +
        props.style
      }
    >
      {props.children}
    </h2>
  );
};

export default Title;
