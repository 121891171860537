import AbstractComponent from "../../strapi_types/AbstractComponent";
import Title from "../Title";
import GalleryImage from "../Gallery/GalleryImage";
import { useGalleryPreview } from "../Gallery/useGalleryPreview";

interface GalleryImageFormats {
  medium: { url: string };
}

export interface GalleryImage {
  url: string;
  index: number;
  formats: GalleryImageFormats;
  openGallery: (index: number) => void;
}

export interface GalleryProps extends AbstractComponent {
  title: string;
  gallery: GalleryImage[];
}

const Gallery: React.FunctionComponent<GalleryProps> = (props) => {
  const [GalleryPreviewPortal, openPreview] = useGalleryPreview(props.gallery);

  const baseUrl = process.env.NEXT_PUBLIC_STRAPI_URL;

  return (
    <section
      className="flex flex-col items-center pt-8 pb-3 px-[3vw] desktop:px-[2vw] desktop:pb-24 desktop:pt-16 mx-auto max-w-container"
      id="gallery"
    >
      <div className="w-full">
        <Title style="text-3xl font-semibold text-flatblack mb-4 desktop:mb-8 leading-lnh44 desktop:leading-lnh71">
          {props.title}
        </Title>
      </div>
      <div className="grid w-full phone:grid-cols-2 desktop:grid-cols-4 gap-[3vw] desktop:gap-[2vw]">
        {props.gallery.slice(0, 4).map((image, index: number) => {
          return (
            <GalleryImage
              key={index}
              url={baseUrl + image.url}
              mediumUrl={baseUrl + image.formats.medium.url}
              index={index}
              openGallery={(id) => openPreview(id)}
            />
          );
        })}
      </div>
      <GalleryPreviewPortal />
    </section>
  );
};

export default Gallery;
