import PageCollection from "../../strapi_types/PageCollection";
import AbstractComponent from "../../strapi_types/AbstractComponent";
import SectionRouter from "../../components/Sections/SectionRouter";

interface SectionContainerProps {
  pageContent: PageCollection;
}

const SectionContainer: React.FunctionComponent<SectionContainerProps> = ({
  pageContent,
}: SectionContainerProps) => {
  return (
    <>
      {pageContent.sections.map(
        (section: AbstractComponent, index: number): JSX.Element => (
          <SectionRouter key={index} section={section} />
        )
      )}
    </>
  );
};

export default SectionContainer;
