interface OverlayProps {
  overlayVisible: boolean;
  onOverlayClick: () => void;
}

const Overlay: React.FunctionComponent<OverlayProps> = (props) => {
  const overlayBody = props.overlayVisible ? (
    <div
      className="w-full h-full z-[150] bg-backdrop absolute top-0 left-0 flex items-center justify-center"
      onClick={() => {
        props.onOverlayClick();
      }}
    >
      {props.children}
    </div>
  ) : null;

  return <>{overlayBody}</>;
};

export default Overlay;
