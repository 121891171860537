import AbstractComponent from "../../strapi_types/AbstractComponent";
import Title from "../Title";
export interface DescriptionProps extends AbstractComponent {
  title: string;
  content: string;
}

const Description: React.FunctionComponent<DescriptionProps> = (props) => {
  return (
    <section
      className="p-4 pb-0 mx-auto my-11 max-w-container"
      id="description"
    >
      <Title style={"text-flatblack mb-4"}>{props.title}</Title>

      <div className="text-lg font-light text-left font-roboto desktop:text-2xl leading-lnh21 desktop:leading-lnh28 ">
        <p>
          Od 2011. do danas kroz vrata brijačnice Tatarević prošlo je mnogo
          muškaraca koji po hitnom postupku treba da postanu ljudi. Muški frizer
          i berberin Tatarević u ulici Svetog Save 27 u Prnjavoru vam je na
          usluzi radnim danima 09:00-17:00 i subotom 09:00-16:00. Da popijemo
          kafu, popričamo i da vas uljudimo.{" "}
        </p>
        <br />
        <p>
          Razumijemo vaše potrebe, pa smo uveli i onlajn zakazivanje kako biste
          što lakše odabrali termin koji vama odgovora. Naručite frizuru i (ili)
          brijanje klikom na ZAKAŽI TERMIN. Vidimo se!
        </p>
      </div>
    </section>
  );
};

export default Description;
