import {
  AppointmentDateTimesDto,
  AppointmentTimesDto,
  MakeAppointmentDto,
} from "../../services/customer/customerTypes";
import { AppState } from "../store";
import { HasAppointmentEnum, UserDataInterface } from "./appointmentTypes";

// APPOINTMENT SELECTORS

export const chosenDaySelector = (
  state: AppState
): AppointmentDateTimesDto | null => {
  try {
    return state.userData.appointment.AvailableAppointmentsPerEmployee[0]
      .appointmentDateTimes[state.userData.appointment.dayIndex];
  } catch {
    return null;
  }
};

export const chosenDayIndexSelector = (state: AppState): number => {
  return state.userData.appointment.dayIndex;
};

export const isDaySelectedSelector = (state: AppState): boolean => {
  return chosenDayIndexSelector(state) !== -1;
};

export const chosenTimeIndexSelector = (state: AppState): number => {
  return state.userData.appointment.timeIndex;
};

export const isTimeSelectedSelector = (state: AppState): boolean => {
  return chosenTimeIndexSelector(state) !== -1;
};

export const chosenTimeSelector = (state: AppState): string | undefined => {
  return chosenDaySelector(state)?.localTimeList[chosenTimeIndexSelector(state)]
    .startTime;
};

export const dayAppointmentsSelector = (
  state: AppState
): AppointmentDateTimesDto[] | [] => {
  try {
    return state.userData.appointment.AvailableAppointmentsPerEmployee[0]
      .appointmentDateTimes;
  } catch {
    return [];
  }
};

export const hasAppointmentsSelector = (state: AppState): boolean => {
  try {
    return dayAppointmentsSelector(state).length > 0;
  } catch {
    return true;
  }
};

export const TimesInSelectedDaySelector = (
  state: AppState
): AppointmentTimesDto[] | [] => {
  try {
    return state.userData.appointment.AvailableAppointmentsPerEmployee[0]
      .appointmentDateTimes[state.userData.appointment.dayIndex].localTimeList;
  } catch {
    return [];
  }
};
export const hasTimesInChosenDaySelector = (state: AppState): boolean => {
  try {
    return TimesInSelectedDaySelector(state).length > 0;
  } catch {
    return true;
  }
};

export const isMakingAppointmentSelector = (state: AppState): boolean => {
  return state.userData.appointment.makingAppointment;
};

// USER SELECTORS

export const isDataLoadingSelector = (state: AppState): boolean => {
  return state.userData.appointment.loadingAppointmentData === true;
};

export const userTokenSelector = (state: AppState): string | null => {
  return state.userData.user.userToken;
};

export const userHasAppointmentSelector = (
  state: AppState
): HasAppointmentEnum => {
  return state.userData.user.hasAppointment;
};

export const userDataSelector = (state: AppState): UserDataInterface => {
  return state.userData.user;
};

export const userAppointmentDataSelector = (
  state: AppState
): MakeAppointmentDto => {
  return state.userData.appointment.appointmentData;
};

export const isUserDataFormValidSelector = (
  state: AppState
): { name: boolean; phone: boolean; email: boolean } => {
  const isNameValid =
    state.userData.appointment.appointmentData.name.length >= 3;
  const isPhoneValid =
    state.userData.appointment.appointmentData.phone.length > 0;
  const isEmailValid = true;

  const isUserDataFormValidObject = { name: false, phone: false, email: false };

  if (isNameValid) {
    isUserDataFormValidObject.name = true;
  }
  if (isPhoneValid) {
    isUserDataFormValidObject.phone = true;
  }
  if (isEmailValid) {
    isUserDataFormValidObject.email = true;
  }
  return isUserDataFormValidObject;
};
